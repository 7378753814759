<template>
   <navi-bar>
     <img src="@/assets_mobile/img/headin_off.png"/>
     <span>{{$t('front.common.gameResult')}}<em>Game Result</em></span>
   </navi-bar>
  <section class="sub-box">
    <div class="moneytab">
      <a :class="{'on' : type == 'sports'}" @click="type = 'sports'"><img src="@/assets_mobile/img/sports.png" /><span>{{$t('front.gameCategory.sports')}}</span></a>
      <a :class="{'on' : type == 'ball' || type == 'ladder'}" @click="type = 'ball'"><img src="@/assets_mobile/img/minigame.png" /><span>{{$t('front.gameCategory.minigame')}}</span></a>
    </div>

    <template v-if="type == 'sports'">
      <div class="coupontab">
        <a class="on">승무패</a>
        <a>핸디캡</a>
        <a>언오버</a>
        <a>스페셜</a>
      </div>
      <div class="navi">
        <a class="on"><img src="@/assets_mobile/img/folder.png"><span>전체</span><em>854</em></a>
        <a><img src="@/assets_mobile/img/soccer.png"><span>축구</span><em>567</em></a>
        <a><img src="@/assets_mobile/img/basketball.png"><span>농구</span><em>277</em></a>
        <a><img src="@/assets_mobile/img/baseball.png"><span>야구</span><em>407</em></a>
        <a><img src="@/assets_mobile/img/volleyball.png"><span>배구</span><em>0</em></a>
        <a><img src="@/assets_mobile/img/hockey.png"><span>하키</span><em>1</em></a>
        <a><img src="@/assets_mobile/img/boxing.png"><span>복싱</span><em>2</em></a>
        <a><img src="@/assets_mobile/img/football.png"><span>럭비</span><em>3</em></a>
      </div>
      <ul class="datewrap">
        <li>
          <input type="text" placeholder="전체">
          <input type="text" placeholder="팀명검색">
        </li>
        <li>
          <input type="date">
          <span>~</span>
          <input type="date">
        </li>
        <li>
          <a class="tday">오늘</a>
          <a class="weeks">1주일</a>
          <a class="hmonth">15일</a>
          <a class="month">1개월</a>
          <a class="search">검색</a>
        </li>
      </ul>
      <div class="betresultwrap">
        <div class="betresulttable">
          <ul class="betresulthead">
            <li class="w38">승(홈)</li>
            <li class="w12">무</li>
            <li class="w38">패(원정)</li>
            <li class="w12">결과</li>
          </ul>
          <ul class="betresulttop">
            <li><img class="h20" src="@/assets_mobile/img/sports_active.png"><span>MLS</span></li>
            <li><img class="h15" src="@/assets_mobile/img/minigame_side_2.png"><span>2021-06-29 15:45</span></li>
          </ul>
          <ul class="betresultbottom">
            <li class="w38 off">
              <span class="textover">CF파추카</span>
              <span>2.26</span>
            </li>
            <li class="w12 on">2.96</li>
            <li class="w38 off">
              <span>3.4</span>
              <span class="textover">과달하라 차바차바</span>
            </li>
            <li class="w12"><span class="gn">무</span><span>4:4</span></li>
          </ul>
          <ul class="betresultbottom">
            <li class="w38 off">
              <span class="textover">CF파추카</span>
              <span>2.26</span>
            </li>
            <li class="w12 off">2.96</li>
            <li class="w38 on">
              <span>3.4</span>
              <span class="textover">과달하라 차바차바</span>
            </li>
            <li class="w12"><span class="rd">승</span><span>1:0</span></li>
          </ul>

          <ul class="betresulttop">
            <li><img class="h20" src="@/assets_mobile/img/sports_active.png"><span>MLS</span></li>
            <li><img class="h15" src="@/assets_mobile/img/minigame_side_2.png"><span>2021-06-29 15:45</span></li>
          </ul>
          <ul class="betresultbottom">
            <li class="w38 on">
              <span class="textover">CF파추카</span>
              <span>2.26</span>
            </li>
            <li class="w12 off">2.96</li>
            <li class="w38 off">
              <span>3.4</span>
              <span class="textover">과달하라 차바차바</span>
            </li>
            <li class="w12"><span>패</span><span>1:0</span></li>
          </ul>
        </div>
      </div>
      <pagination-mobile />

    </template>

    <template v-if="type == 'ball' || type == 'ladder'">
      <div class="navi3">
        <a @click="type = 'ball'" :class="{'on' : type == 'ball'}" ><img src="@/assets_mobile/img/powerballs.png"><span>파워볼</span></a>
        <a @click="type = 'ladder'" :class="{'on' : type == 'ladder'}"><img src="@/assets_mobile/img/powerladders.png"><span>파워사다리</span></a>
        <a><img src="@/assets_mobile/img/speedkinos.png"><span>스피드키노</span></a>
        <a><img src="@/assets_mobile/img/kinoladders.png"><span>키노사다리</span></a>
      </div>

      <template v-if="type == 'ball'">
        <ul class="datewrap">
          <li>
            <input type="date">
            <select>
              <option>전체회차</option>
              <option>1회차</option>
            </select>
          </li>
          <li><a class="search">검색</a></li>
        </ul>
        <div class="betresultwrap">
          <div class="betresulttable">
            <ul class="betresulttop">
              <li><img class="h20" src="@/assets_mobile/img/powerballs.png"><span>파워볼</span></li>
            </ul>
            <div class="betresultmini">
              <ul class="minilist">
                <li>경기일시 : <span>2021-06-29 15:45</span></li>
                <li>회차 : <span>211</span></li>
                <li>결과 : <span class="bbl">파워볼 숫자 (2)</span></li>
                <li>배당률 : <span>9.00</span></li>
              </ul>
              <ul class="minilist">
                <li>경기일시 : <span>2021-06-29 15:45</span></li>
                <li>회차 : <span>211</span></li>
                <li>결과 : <span class="bbl">파워볼 숫자 (2)</span></li>
                <li>배당률 : <span>9.00</span></li>
              </ul>
              <ul class="minilist">
                <li>경기일시 : <span>2021-06-29 15:45</span></li>
                <li>회차 : <span>211</span></li>
                <li>결과 : <span class="bbl">파워볼 숫자 (2)</span></li>
                <li>배당률 : <span>9.00</span></li>
              </ul>
            </div>

            <ul class="betresulttop">
              <li><img class="h20" src="@/assets_mobile/img/powerballs.png"><span>파워볼</span></li>
            </ul>
            <div class="betresultmini">
              <ul class="minilist">
                <li>경기일시 : <span>2021-06-29 15:45</span></li>
                <li>회차 : <span>211</span></li>
                <li>결과 : <span class="bbl">파워볼 숫자 (2)</span></li>
                <li>배당률 : <span>9.00</span></li>
              </ul>
              <ul class="minilist">
                <li>경기일시 : <span>2021-06-29 15:45</span></li>
                <li>회차 : <span>211</span></li>
                <li>결과 : <span class="bbl">파워볼 숫자 (2)</span></li>
                <li>배당률 : <span>9.00</span></li>
              </ul>
              <ul class="minilist">
                <li>경기일시 : <span>2021-06-29 15:45</span></li>
                <li>회차 : <span>211</span></li>
                <li>결과 : <span class="bbl">파워볼 숫자 (2)</span></li>
                <li>배당률 : <span>9.00</span></li>
              </ul>
            </div>
          </div>
        </div>

        <pagination-mobile />
      </template>

      <template v-if="type == 'ladder'">
        <ul class="datewrap">
          <li>
            <input type="date">
            <select>
              <option>전체회차</option>
              <option>1회차</option>
            </select>
          </li>
          <li><a class="search">검색</a></li>
        </ul>

        <div class="betresultwrap">
          <div class="betresulttable">
            <ul class="betresulthead">
              <li class="w38">승</li>
              <li class="w12">VS</li>
              <li class="w38">패</li>
              <li class="w12">회차</li>
            </ul>
            <ul class="betresulttop">
              <li><img class="h20" src="@/assets_mobile/img/powerladders.png"><span>파워사다리</span></li>
              <li><img class="h15" src="@/assets_mobile/img/minigame_side_2.png"><span>2021-06-29 15:45</span></li>
            </ul>
            <ul class="betresultbottom">
              <li class="w38 off">
                <span class="textover">홀[211회]</span>
                <span>2.26</span>
              </li>
              <li class="w12 on">2.96</li>
              <li class="w38 off">
                <span>3.4</span>
                <span class="textover">[211회]짝</span>
              </li>
              <li class="w12"><span class="lh22">211</span></li>
            </ul>
            <ul class="betresultbottom">
              <li class="w38 off">
                <span class="textover">3줄[211회]</span>
                <span>2.26</span>
              </li>
              <li class="w12 off">2.96</li>
              <li class="w38 on">
                <span>3.4</span>
                <span class="textover">[211회] 4줄</span>
              </li>
              <li class="w12"><span class="lh22">211</span></li>
            </ul>

            <ul class="betresulttop">
              <li><img class="h20" src="@/assets_mobile/img/powerladders.png"><span>파워사다리</span></li>
              <li><img class="h15" src="@/assets_mobile/img/minigame_side_2.png"><span>2021-06-29 15:45</span></li>
            </ul>
            <ul class="betresultbottom">
              <li class="w38 on">
                <span class="textover">CF파추카</span>
                <span>2.26</span>
              </li>
              <li class="w12 off">2.96</li>
              <li class="w38 off">
                <span>3.4</span>
                <span class="textover">과달하라 차바차바</span>
              </li>
              <li class="w12"><span class="lh22">211</span></li>
            </ul>
          </div>
        </div>

        <pagination-mobile />
      </template>

    </template>

  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'

export default {
  name: 'game',
  components: {
    NaviBar,
    PaginationMobile
  },
  data () {
    return {
      type: 'sports'
    }
  }
}
</script>

<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
